<!--服务协议-->
<template>
  <div style="tab-interval:36pt;padding: 0 8px 8px;">
    <div class="Section0">
      <h1><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:16.0000pt;">PDBox<font face="宋体">平台服务协议</font></span></b><b>
        <span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:16.0000pt;"></span></b></h1>
      <p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">
      <font face="Cambria">您在开始使用</font>PDBox平台相关产品以及服务之前，请您务必仔细阅读并充分理解本《PDBox平台服务协议》。 同时，您还应仔细阅读并充分理解PDBox平台的隐私政策等系列政策和声明。在本服务条款中，请您务必特别注意及重点阅读与您的权利及义务密切相关的条款，此类条款可能以粗体加黑的方式标记，包括但不限于免责条款等。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">1 <font face="宋体">接受服务协议</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">
      <font face="Cambria">本服务协议是您与上海卫心科技有限公司就您使用</font>PDBox平台服务所签署的服务协议。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p>
      <p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">
      <font face="Cambria">请您仔细阅读以下条款，以帮助您更好地使用</font>PDBox平台相关产品以及服务。如果您对本协议的任何条款表示异议，您可以选择不进入 PDBox平台。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">1.1 当您进行注册并提交审核，无论是进入 PDBox平台，还是在 PDBox平台上发布任何内容（即「内容」），均意味着您（即「用户」）完全接受本协议项下的全部条款。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">1.2 如您未满 18 周岁，请在法定监护人的陪同下阅读本服务协议，并特别注意未成年人使用的相关条款。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">1.3 本协议是您与上海卫心科技有限公司（以下简称“我们”）之间就您注册、登录、使用PDBox平台、小程序及网页，并获得 PDBox平台提供的相关服务所订立的协议。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">2 PDBox<font face="宋体">如何提供服务</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.1 我们非常重视创新以及用户体验，因此PDBox平台提供的服务的形式和本质可能不时变换，而这些变化有可能并不进行事先通知。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.2 PDBox平台相关服务更新或部分服务内容更新后，在可能的情况下， 我们将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务， 如用户不接受，部分功能将受到限制或不能继续使用。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.3 PDBox平台相关服务中包含公司以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联公司合法运营的其他单项服务。这些服务在 PDBox平台可能以单独模块形式存在。 公司有权不时地增加、减少或改动这些模块的设置及服务。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.4 PDBox平台可自行决定，无须事先通知您停止（永久或暂时）向您或全体用户提供服务。您可在任何时候停止使用服务。您停止使用服务时无需特别通知PDBox平台。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.5 您认知并同意，如果我们经过谨慎考虑后禁用对您的帐户的访问权，则您可能被阻止获得服务、您的帐户资料或包含在您帐户中的任何文件或其他内容。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.6 您认知并同意，尽管 PDBox平台可能目前没有设置您可通过服务发送或接收的传输数量或用于提供任何服务的存储空间的上限，但考虑服务器资源分配我们可自行决定在任何时候设置上限。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.7 一旦您在其终端设备中打开PDBox网站或访问PDBox移动应用，即视为您使用PDBox平台及相关服务。 为充分实现 PDBox平台的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.8 PDBox平台服务所提供的部分信息可以协助医生、药师和其它医疗专业人员处理在药物治疗中遇到的问题。但是，PDBox平台提供的一切文本、图像信息仅供作为咨询信息使用，并不能代替上述医疗专业人员在临床工作中作出治疗判断或决定。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.9 PDBox平台服务提供的信息来源于临床和基础研究专著、论文和临床报道。尽管上海卫心科技有限公司专业人员已经对文献进行科学评估并经专家学术委员会审定，全部工作也经过中国国家药典委员会、国家药品评价中心的共同监制，PDBox平台仍然无法完全确保信息的完全正确，因此上述信息无论用于何种目的而可能导致的后果，PDBox平台均不承担任何法律责任。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">2.10 PDBox平台提供的信息可能含有品牌、厂商信息，但我们无意向用户、任何企业或个人推荐或诱导使用或研究平台上以各种形式收载的所有药品，也不推荐或支持任何特定的检验、观点或其他信息。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">3 <font face="宋体">平台使用规则</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.1 用户注册成功后，我们将给予每个用户一个用户帐号及相应的密码，该用户帐号和密码由用户负责保管；用户应当对以其用户帐号进行的所有活动和事件负法律责任。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.2 您应按照PDBox的网站页面提示及本服务协议的约定支付相应服务费用。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.3 就PDBox平台服务的使用应符合PDBox平台的《服务使用规则》以及本服务协议。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.4 用户须对在PDBox平台的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人； 不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认；否则PDBox平台有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.5 用户直接或通过各类方式（如 站外 API 引用等）间接使用PDBox平台服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用PDBox平台所提供的全部服务。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.6用户承诺不得以任何方式利用PDBox平台直接或间接从事违反中国法律以及社会公德的行为，PDBox平台有权对违反上述承诺的内容予以删除。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.7 PDBox平台是一个医药健康相关信息提供的平台，用户通过PDBox平台发表的部分信息为公开的信息，其他第三方均可以通过PDBox平台获取用户发表的这些信息，用户单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在PDBox平台上进行发表。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.8 用户不得利用 PDBox平台服务制作、上载、复制、发布、传播或者转载如下内容： </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">反对宪法所确定的基本原则的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">损害国家荣誉和利益的；</font> <font face="Cambria">煽动民族仇恨、民族歧视，破坏民族团结的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">破坏国家宗教政策，宣扬邪教和封建迷信的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">散布谣言，扰乱社会秩序，破坏社会稳定的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">侮辱或者诽谤他人，侵害他人合法权益的；</font> </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">含有法律、行政法规禁止的其他内容的信息。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">3.9 我们有权对用户使用PDBox平台的情况进行审查和监督，如用户在使用PDBox平台时违反任何上述规定，PDBox平台或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户上传、发布的内容、暂停或终止用户使用PDBox平台的权利）以减轻用户不当行为造成的影响。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">4 <font face="宋体">账户安全</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;">4.1 您同意并理解您有责任将您与用于获得服务的任何帐户相关的密码保密。 您对自己存放在PDBox平台上的数据以及进入和管理PDBox平台服务的账号、密码的完整性和保密性负责。因您维护不当或保密不当致使上述数据、账号、密码等丢失或泄漏所引起的损失和后果均由您承担。</span></b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">4.2 您同意将独自就在您帐户下的所有活动对PDBox平台负责。 </span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">4.3 如果您得知任何对您的密码或您的帐户的任何未经授权的使用，您同意立即通知PDBox平台。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">4.4 关于 PDBox平台的数据保护惯例的信息，请查阅我们的《PDBox平台隐私政策》。 该政策解释了 PDBox平台如何处理您的个人信息，并在您使用服务时保护您的隐私。您同意按照 PDBox平台的隐私政策使用您的数据。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">5 <font face="宋体">知识产权</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:bold;font-size:12.0000pt;">5.1 在本协议项下一方向对方提供的任何资料、技术或技术支持、软件、服务等的知识产权均属于提供一方或其合法权利人所有； 除提供方或合法权利人明示同意外，另一方无权复制、传播、转让、许可或提供他人使用上述知识成果，否则应承担相应的责任。</span></b><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">5.2 您应保证提交PDBox平台的素材、对PDBox平台服务的使用及使用PDBox平台服务所产生的成果未侵犯任何第三方的合法权益。对应的，我们应保证向您提供的服务未侵犯任何第三方的合法权益。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">5.3 如果第三方机构或个人对您使用我们的服务所涉及的相关素材的知识产权归属提出质疑或投诉，或对您使用的我们的服务的知识产权的归属提出质疑或投诉，您和我们均有责任出具相关知识产权证明材料，并配合对方的相关投诉处理工作。对于因此引起的索赔、诉讼或可能向其提起诉讼,违约方应负责解决，承担费用和损失，以及使另一方免责。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">6 <font face="宋体">保密条款</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">6.1 本服务条款所称保密信息，是指一方（以下简称“接受方”）从对方（以下简称“披露方”）取得的、获知的、或因双方履行本协议而产生的商业秘密（包括财务秘密）、技术秘密、经营诀窍和（或）其他应予保密的信息和资料（包括产品资料，产品计划，价格，财务及营销规划，业务战略，客户信息，客户数据，研发，软件，硬件，API应用数据接口，技术说明，设计，特殊公式，特殊算法等），无论上述信息和资料以何种形式或载于何种载体，无论披露方在披露时是否以口头、图像或书面等方式表明其具有保密性。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">6.2 双方应采取适当措施妥善保存对方提供的保密信息，对待双方保密信息措施的审慎程度应该近似相同。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">6.3 双方保证保密信息仅可在各自一方从事该业务的负责人和雇员范围内知悉，并严格限制接触上述保密信息的员工遵守本条之保密义务。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">6.4 本条上述限制条款不适用于以下情况：</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">在签署本协议之时或之前，该保密信息已以合法方式属接受方所有；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">保密信息在通知给接受方时或之前，已经公开或能从公开领域获得；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span>
      <!--[endif]-->
      <span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">接受方应法院或其它法律、行政管理部门要求（通过口头提问、询问、要求资料或文件、传唤、民事或刑事调查或其他程序）因而透露保密信息；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;">
      <!--[if !supportLists]-->
      <span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">接受方为向行政管理部门、行业协会等机构申请某项业务资质、获得某项认定、或符合国家、行业标准</font>/认证，需结合对方情况向前述机构提交材料或进行说明的而披露的信息，在该等情况下，接受方应秉持必要情况下最少披露原则及要求因此获知保密信息的机构按不低于本协议的标准予以保密。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">6.5 您和我们都应尽最大的努力保护上述保密信息不被泄露。一旦发现有上述保密信息泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生。如因此给对方造成损失的，应赔偿因此给对方造成的直接经济损失。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">7 <font face="宋体">服务的开通、终止与变更</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">7.1 您付费后服务即开通，开通后您获得我们向您发送的账号以及对应的密码即可登录平台使用服务，服务期限自开通之时起算（而非自您获得登录、使用服务的密钥、口令时起算）；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">7.2 发生下列情形之一的，服务终止：</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">双方协商一致终止；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您严重违反本协议（包括，您严重违反相关法律法规规定，或您严重违反本协议项下之任一承诺内容等），我们有权终止服务直至清除您的全部数据；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">您理解并充分认可，虽然我们已经建立（并将根据技术的发展不断完善）必要的技术措施来防御包括计算机病毒、网络入侵和攻击破坏（包括</font>DDoS）等危害网络安全事项或行为（以下统称该等行为），但鉴于网络安全技术的局限性、相对性以及该等行为的不可预见性，因此如因您网站遭遇该等行为而给我们或者我们的其他网络或服务器带来危害，或影响我们与国际互联网或者我们与特定网络、服务器及PDBox平台内部的通畅联系，我们可决定暂停或终止服务。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">我们可提前</font>30天在PDBox平台公告给您发站内通知或书面通知的方式终止本服务；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">7.3 您理解并认可，为技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化，我们不保证永久的提供某种服务，并有权变更所提供服务的形式、规格或其他方面（如服务的价格和计费模式），在终止该种服务或进行此种变更前，我们将尽最大努力且提前以网站公告、站内信、邮件或短信等一种或多种方式进行事先通知。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">8 <font face="宋体">违约责任</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">8.1 您违反本协议中的承诺、保证条款、服务使用规则或义务的任一内容，或我们根据其判断认为您的使用行为存在异常的，我们均有权就其情节，根据独立判断并单方采取以下措施中的一种或多种：</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">（</font>1）限制、中止使用服务；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">（</font>2）终止提供服务，终止本协议；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">（</font>3）追究您的法律责任；</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">（</font>4）其他我们认为适合的处理措施。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">PDBox平台依据前述约定采取中止服务、终止服务等措施而造成的用户损失将由您承担。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">8.2 如因您违反有关法律法规或者本协议、相关规则之规定，使PDBox平台遭受任何损失、受到其他用户、任何第三方的索赔或任何行政管理部门的处罚，您应对PDBox平台、其他用户或相关第三方的实际损失进行全额赔偿，包括合理的律师费用。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">8.3 您理解且同意，鉴于计算机、互联网的特殊性，下述情况不属于PDBox平台违约：</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">在进行系统及服务器配置、维护、升级时，需要短时间中断服务；</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoNormal" style="margin-left:36.0000pt;text-indent:-24.0000pt;mso-list:l0 level1 lfo1;"><!--[if !supportLists]--><span style="font-family:Cambria;mso-bidi-font-family:'Times New Roman';font-size:12.0000pt;"><span style="mso-list:Ignore;">•<span>&nbsp;</span></span></span><!--[endif]--><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"><font face="Cambria">由于</font>Internet上的通路阻塞造成您网站访问速度下降。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">8.4 在任何情况下，PDBox平台均不对任何间接性、后果性、惩戒性、偶然性、特殊性的损害，包括您使用PDbox平台服务而遭受的利润损失承担责任（即使您已被告知该等损失的可能性）。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">8.5 在法律允许的情况下，PDBox平台在本协议项下所承担的损失赔偿责任不超过就该服务所缴纳的服务费用的总和。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">9 <font face="宋体">不可抗力</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">&nbsp;<font face="Cambria">不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件，包括自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、黑客、网路堵塞、电信部门技术调整和政府管制等。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">&nbsp;<font face="Cambria">因不可抗力或者其他意外事件，使得本服务条款的履行不可能、不必要或者无意义的，遭受不可抗力、意外事件的一方不承担责任。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">10 <font face="宋体">通知</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">10.1 您在使用PDBox平台服务时，您应该向PDBox平台提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。您接收站内信、系统消息的会员账号（包括子账号），也作为您的有效联系方式。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，将由您承担由此可能产生的法律后果。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">10.2 PDBox平台将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">10.3 PDBox平台通过上述联系方式向您发出的通知，其中以电子的方式发出的书面通知，包括公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><h2><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;">11</span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;"><font face="宋体">争议解决</font></span></b><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;"></span></b></h2><h2><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
      font-style:normal;font-size:12.0000pt;"><font face="Cambria">凡因本</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';letter-spacing:0.0000pt;
      font-weight:normal;text-transform:none;font-style:normal;
      font-size:12.0000pt;"><font face="宋体">协议</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
      font-style:normal;font-size:12.0000pt;"><font face="Cambria">引起的或与本</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';letter-spacing:0.0000pt;
      font-weight:normal;text-transform:none;font-style:normal;
      font-size:12.0000pt;"><font face="宋体">协议</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
      font-style:normal;font-size:12.0000pt;"><font face="Cambria">有关的任何争议，</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';letter-spacing:0.0000pt;
      font-weight:normal;text-transform:none;font-style:normal;
      font-size:12.0000pt;"><font face="宋体">如协商不成</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
      font-style:normal;font-size:12.0000pt;"><font face="Cambria">均应提交上海仲裁委员会按照该会仲裁规则进行仲裁。仲裁裁决是终局的，对双方当事人均有约束力</font></span><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Cambria;
      mso-hansi-font-family:Cambria;mso-bidi-font-family:'Times New Roman';letter-spacing:0.0000pt;
      font-weight:normal;text-transform:none;font-style:normal;
      font-size:12.0000pt;"><font face="宋体">。</font></span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-weight:normal;font-size:12.0000pt;"></span></h2><h2><b><span style="mso-spacerun:'yes';font-family:宋体;mso-ascii-font-family:Calibri;
      mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);
      font-weight:bold;font-size:14.0000pt;">12</span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"><font face="宋体">附则</font></span></b><b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
      mso-bidi-font-family:'Times New Roman';color:rgb(79,129,189);font-weight:bold;
      font-size:14.0000pt;"></span></b></h2><p class="59"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">11.1 本协议的附件，以及PDBox平台在PDBox网站相关页面上的服务说明、价格说明和您确认同意的订购页面（包括产品的专用条款、服务说明、操作文档等）均为本协议不可分割的一部分。如遇不一致之处，以（1）服务说明、价格说明、其他订购页面，（2）专用条款和附件，（3）本协议通用条款的顺序予以适用。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">11.2 如本协议内容发生变动，PDBox平台应通过提前30天在PDBox网站的适当版面公告向您提示修改内容；如您继续使用PDBox平台服务，则视为您接受PDBox平台对本协议所做的相关修改。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">11.3 PDBox平台有权经提前通知将本协议的权利义务全部或者部分转移给PDBox平台的关联公司。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">11.4 PDBox平台予您过失或违约时放弃本协议规定的权利，不应视为其对您的其他或以后同类之过失或违约行为弃权。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">11.5 如本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p><p class="MsoBodyText"><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;">11.6 本协议项下之保证条款、保密条款、知识产权条款、法律适用及争议解决条款等内容，不因本协议的终止而失效。</span><span style="mso-spacerun:'yes';font-family:Cambria;mso-bidi-font-family:'Times New Roman';
      font-size:12.0000pt;"></span></p></div>
  </div>
</template>
<script>
  export default {
    name: 'ServiceAgreement'
  }
</script>
<style scoped>
  @font-face{
  font-family:"Times New Roman";
  }

  @font-face{
  font-family:"����";
  }

  @font-face{
  font-family:"Wingdings";
  }

  @font-face{
  font-family:"Consolas";
  }

  @font-face{
  font-family:"Calibri";
  }

  @font-face{
  font-family:"Cambria";
  }

  @list l0:level1{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:36.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level2{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:72.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level3{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:108.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level4{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:144.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level5{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:180.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level6{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:216.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level7{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:252.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level8{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2013;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:288.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  @list l0:level9{
  mso-level-start-at:0;
  mso-level-number-format:bullet;
  mso-level-suffix:tab;
  mso-level-text:\2022;
  mso-level-tab-stop:none;
  mso-level-number-position:left;
  margin-left:324.0000pt;text-indent:-24.0000pt;font-family:'Times New Roman';}

  p.MsoNormal{
  mso-style-name:����;
  mso-style-parent:"";
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  h1{
  mso-style-name:"���� 1";
  mso-style-next:�����ı�;
  margin-top:24.0000pt;
  margin-bottom:20px;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:1;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-weight:bold;
  font-size:16.0000pt;
  }

  h2{
  mso-style-name:"���� 2";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:20px;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:2;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-weight:bold;
  font-size:14.0000pt;
  }

  h3{
  mso-style-name:"���� 3";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:3;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-weight:bold;
  font-size:12.0000pt;
  }

  h4{
  mso-style-name:"���� 4";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:4;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-style:italic;
  font-size:12.0000pt;
  }

  h5{
  mso-style-name:"���� 5";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:5;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  h6{
  mso-style-name:"���� 6";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:6;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  p.MsoHeading7{
  mso-style-name:"���� 7";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:7;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  p.MsoHeading8{
  mso-style-name:"���� 8";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:8;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  p.Msoheading9{
  mso-style-name:"���� 9";
  mso-style-noshow:yes;
  mso-style-next:�����ı�;
  margin-top:10.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  mso-outline-level:9;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(79,129,189);
  font-size:12.0000pt;
  }

  span.10{
  font-family:Wingdings;
  }

  span.15{
  font-family:Wingdings;
  vertical-align:super;
  }

  span.16{
  font-family:Wingdings;
  color:rgb(79,129,189);
  }

  span.17{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.18{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.19{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.20{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.21{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.22{
  font-family:Wingdings;
  }

  span.23{
  font-family:Wingdings;
  }

  span.24{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.25{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  span.26{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.27{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.28{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.29{
  font-family:Consolas;
  color:rgb(125,144,41);
  font-size:11.0000pt;
  }

  span.30{
  font-family:Consolas;
  color:rgb(6,40,126);
  font-size:11.0000pt;
  }

  span.31{
  font-family:Consolas;
  color:rgb(188,122,0);
  font-size:11.0000pt;
  }

  span.32{
  font-family:Consolas;
  color:rgb(0,112,32);
  font-size:11.0000pt;
  }

  span.33{
  font-family:Consolas;
  color:rgb(187,102,136);
  font-size:11.0000pt;
  }

  span.34{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.35{
  font-family:Consolas;
  color:rgb(102,102,102);
  font-size:11.0000pt;
  }

  span.36{
  font-family:Consolas;
  color:rgb(25,23,124);
  font-size:11.0000pt;
  }

  span.37{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-style:italic;
  font-size:11.0000pt;
  }

  span.38{
  font-family:Consolas;
  color:rgb(144,32,0);
  font-size:11.0000pt;
  }

  span.39{
  font-family:Consolas;
  color:rgb(64,112,160);
  font-size:11.0000pt;
  }

  span.40{
  font-family:Consolas;
  color:rgb(136,0,0);
  font-size:11.0000pt;
  }

  span.41{
  font-family:Consolas;
  color:rgb(64,160,112);
  font-size:11.0000pt;
  }

  span.42{
  font-family:Consolas;
  color:rgb(0,112,32);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.43{
  font-family:Consolas;
  color:rgb(64,160,112);
  font-size:11.0000pt;
  }

  span.44{
  font-family:Consolas;
  color:rgb(0,112,32);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.45{
  font-family:Consolas;
  color:rgb(186,33,33);
  font-style:italic;
  font-size:11.0000pt;
  }

  span.46{
  font-family:Consolas;
  color:rgb(64,160,112);
  font-size:11.0000pt;
  }

  span.47{
  font-family:Consolas;
  color:rgb(96,160,176);
  font-weight:bold;
  font-style:italic;
  font-size:11.0000pt;
  }

  span.48{
  font-family:Consolas;
  color:rgb(255,0,0);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.49{
  font-family:Consolas;
  color:rgb(255,0,0);
  font-weight:bold;
  font-size:11.0000pt;
  }

  span.50{
  font-family:Consolas;
  font-size:11.0000pt;
  }

  p.MsoFootnoteText{
  mso-style-name:��ע�ı�;
  mso-style-noshow:yes;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoSubtitle{
  mso-style-name:������;
  mso-style-parent:����;
  mso-style-next:�����ı�;
  margin-top:12.0000pt;
  margin-bottom:12.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(51,91,138);
  font-weight:bold;
  font-size:15.0000pt;
  }

  p.MsoDate{
  mso-style-name:����;
  mso-style-next:�����ı�;
  margin-bottom:10.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoBodyText{
  mso-style-name:�����ı�;
  margin-top:9.0000pt;
  margin-bottom:9.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoTitle{
  mso-style-name:����;
  mso-style-next:�����ı�;
  margin-top:24.0000pt;
  margin-bottom:12.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(51,91,138);
  font-weight:bold;
  font-size:18.0000pt;
  }

  p.MsoBlockText{
  mso-style-name:�ı���;
  mso-style-noshow:yes;
  mso-style-parent:�����ı�;
  mso-style-next:�����ı�;
  margin-top:5.0000pt;
  margin-right:24.0000pt;
  margin-bottom:5.0000pt;
  margin-left:24.0000pt;
  text-indent:0.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.MsoCaption{
  mso-style-name:��ע;
  mso-style-next:����;
  margin-top:0.0000pt;
  margin-bottom:6.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-style:italic;
  font-size:12.0000pt;
  }

  p.58{
  mso-style-name:"Definition Term";
  mso-style-next:Definition;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-weight:bold;
  font-size:12.0000pt;
  }

  p.59{
  mso-style-name:"First Paragraph";
  mso-style-parent:�����ı�;
  mso-style-next:�����ı�;
  margin-top:9.0000pt;
  margin-bottom:9.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.60{
  mso-style-name:Compact;
  mso-style-parent:�����ı�;
  margin-top:1.8000pt;
  margin-bottom:1.8000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.61{
  mso-style-name:"Source Code";
  margin-bottom:10.0000pt;
  word-break:break-all;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.62{
  mso-style-name:"Captioned Figure";
  mso-style-parent:Figure;
  margin-bottom:10.0000pt;
  page-break-after:avoid;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.63{
  mso-style-name:Author;
  mso-style-next:�����ı�;
  margin-bottom:10.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  text-align:center;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.64{
  mso-style-name:"TOC Heading";
  mso-style-noshow:yes;
  mso-style-parent:"���� 1";
  mso-style-next:�����ı�;
  margin-top:12.0000pt;
  margin-bottom:0.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  line-height:107%;
  font-family:Calibri;
  mso-fareast-font-family:����;
  mso-bidi-font-family:'Times New Roman';
  color:rgb(54,96,145);
  font-weight:normal;
  font-size:16.0000pt;
  }

  p.65{
  mso-style-name:Figure;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.66{
  mso-style-name:"Image Caption";
  mso-style-parent:��ע;
  margin-top:0.0000pt;
  margin-bottom:6.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-style:italic;
  font-size:12.0000pt;
  }

  p.67{
  mso-style-name:Definition;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.68{
  mso-style-name:Abstract;
  mso-style-next:�����ı�;
  margin-top:15.0000pt;
  margin-bottom:15.0000pt;
  page-break-after:avoid;
  mso-pagination:lines-together;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:10.0000pt;
  }

  p.69{
  mso-style-name:Bibliography;
  margin-bottom:10.0000pt;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-size:12.0000pt;
  }

  p.70{
  mso-style-name:"Table Caption";
  mso-style-parent:��ע;
  margin-top:0.0000pt;
  margin-bottom:6.0000pt;
  page-break-after:avoid;
  font-family:Cambria;
  mso-bidi-font-family:'Times New Roman';
  font-style:italic;
  font-size:12.0000pt;
  }

  span.msoIns{
  mso-style-type:export-only;
  mso-style-name:"";
  text-decoration:underline;
  text-underline:single;
  color:blue;
  }

  span.msoDel{
  mso-style-type:export-only;
  mso-style-name:"";
  text-decoration:line-through;
  color:red;
  }

  table.MsoNormalTable{
  mso-style-name:��ͨ����;
  mso-style-parent:"";
  mso-style-noshow:yes;
  mso-tstyle-rowband-size:0;
  mso-tstyle-colband-size:0;
  mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin:0pt;
  mso-para-margin-bottom:.0001pt;
  mso-pagination:widow-orphan;
  font-family:'Times New Roman';
  font-size:10.0000pt;
  mso-ansi-language:#0400;
  mso-fareast-language:#0400;
  mso-bidi-language:#0400;
  }

  table.71{
  mso-style-name:Table;
  mso-tstyle-rowband-size:0;
  mso-tstyle-colband-size:0;
  mso-padding-alt:0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin:0pt;
  mso-para-margin-bottom:.0001pt;
  mso-pagination:widow-orphan;
  font-family:'Times New Roman';
  font-size:10.0000pt;
  mso-ansi-language:#0400;
  mso-fareast-language:#0400;
  mso-bidi-language:#0400;
  }

  table.71FirstRow{
  mso-style-name:Table;
  mso-table-condition:first-row;
  mso-tstyle-border-bottom:0.0000pt solid windowtext;
  mso-tstyle-vert-align:bottom;
  }
  @page{mso-page-border-surround-header:no;
    mso-page-border-surround-footer:no;}@page Section0{
  }
  div.Section0{page:Section0;}
</style>
